.mapboxgl-ctrl-geolocate-custom-buttons__container {
  z-index: 1;
  position: fixed;
  top: calc(100% - 196px);
  right: 10px;
}

.mapboxgl-ctrl-geolocate-custom-buttons__container-theme-dark {
  z-index: 1;
  position: fixed;
  top: calc(100% - 196px);
  right: 10px;
}

.mapboxgl-ctrl-geolocate-custom-button {
  height: 50px;
  width: 50px;
  margin: 0;
  border-radius: 50%;
  border: none;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(17, 17, 17, 0.2);
  display: block;
}

.mapboxgl-ctrl-geolocate-custom-buttons__container-theme-dark .mapboxgl-ctrl-geolocate-custom-button {
  background-color: #232527;
  box-shadow: none;
}

.mapboxgl-ctrl-geolocate-custom-button:active {
  background-color: #bbbbbb;
}

.mapboxgl-ctrl-geolocate-custom-buttons__container-theme-dark .mapboxgl-ctrl-geolocate-custom-button:active {
  background-color: #1c1c1f;
}

.mapboxgl-ctrl-geolocate-custom-button img {
  height: 18px;
  width: 18px;
  margin: 3px 1px 0 0;
}

/*  */

.mapboxgl-ctrl-compass-custom-button {
  height: 30px;
  width: 30px;
  margin: 0 0 10px 0;
  border-radius: 50%;
  border: none;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(17, 17, 17, 0.2);
  padding: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  visibility: hidden;
}

.mapboxgl-ctrl-geolocate-custom-buttons__container-theme-dark .mapboxgl-ctrl-compass-custom-button {
  background-color: #232527;
  box-shadow: none;
}

.mapboxgl-ctrl-compass-custom-button:active {
  background-color: #bbbbbb;
}

.mapboxgl-ctrl-geolocate-custom-buttons__container-theme-dark .mapboxgl-ctrl-compass-custom-button:active {
  background-color: #1c1c1f;
}

.mapboxgl-ctrl-compass-custom-button img {
  height: 26px;
  width: 26px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}