.iphone-install-instructions h3 {
  font-size: 17px;
  text-align: center;
  color: black;
}

.install-instructions__container-theme-dark .iphone-install-instructions h3 {
  color: white;
}

.iphone-install-instructions p {
  font-size: 13px;
  text-align: center;
  margin: -10px 0 10px 0;
  color: black;
}

.install-instructions__container-theme-dark .iphone-install-instructions p {
  color: white;
}

.iphone-install-instructions a {
  text-decoration: none;
  color: #0a84fe;
}

.iphone-install-instructions-lauch-safari-pic img {
  width: 20vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 18px;
}

.iphone-install-instructions-share-button-pic img {
  width: 50vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}

.iphone-install-instructions-add-to-home-screen-button-pic img {
  width: 50vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}