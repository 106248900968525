.options__container {
  display: none;
  z-index: 10000;
}

.options__container-theme-dark {
  z-index: 10000;
}

.options__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: black; */
  /* opacity: 0.15; */
  z-index: 10000;
}

.options {
  /* display: flex; */
  list-style-type: none;
  margin: 0;
  padding: 0;
  /* height: 25px; */
  position: fixed;
  right: calc(50vw - 290px);
  top: 210px;
  /* float: right; */
  z-index: 10000;
  -ms-transform: translateY(10%);
  transform: translateY(10%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 29px 0px rgba(100, 100, 111, 0.3);
  opacity: 1;
}

.options__container-theme-dark .options {
  box-shadow: 0px 0px 20px 0px rgba(17, 17, 17, 0.8);
}

.options li {
  border-top: 1px solid #ddd;
  background-color: #eeeeee;
  /* opacity: 1; */
  height: 45px;
  width: 250px;
}

.options__container-theme-dark .options li {
  border-top: 1px solid #686c70;
  background-color: #2d2e2e;
}

.options li:first-child {
  border-top: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.options li:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.options li p {
  color: black;
}

.options__container-theme-dark .options li p {
  color: white;
}

.options__divider {
  height: 100%;
  width: 100%;
  background-color: #ddd;
}

.options__container-theme-dark .options__divider {
  background-color: #262628;
}

/*  */

.option {
  border: none;
  background-color: inherit;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* display: flex; */
  /* white-space: nowrap; */
}

.first {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.last {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.option:active {
  background-color: #bbbbbb;
}

.option p {
  /* display: inline-block; */
  color: black;
  font-size: 17px;
  margin: 0;
  padding: 0;
  /* text-align: left; */
  /* vertical-align: middle; */
  /* margin-left: 10px; */
  position: fixed;
  left: 15px;
  margin-top: -10px;
  /* font-family: Arial, Helvetica, sans-serif; */
  /* width: 80%; */
  /* height: 100%; */
}

.option img {
  width: 22px;
  height: 22px;
  position: fixed;
  right: 10px;
  margin-top: -9px;
  /* display: inline-block; */
}

@media screen and (max-width: 600px) {
  .options {
    right: 10px;
  }
}