.departures-table__container, .departures-table__container-theme-dark {
  /* width: 100%; */
  /* height: 100%; */
}

.departures-table__container .loading-err {
  text-align: center;
  color: black;
}

.departures-table__container-theme-dark .loading-err {
  text-align: center;
  color: white;
}

#departures-table {
  /* position: static; */
  padding: 0;
  margin: 0;
  border-collapse: collapse;
  width: 100%;
  /* max-width: 600px; */
  /* width: calc(); */
  /* max-width: 600px; */
  /* width: 100%; */
  /* border-style: solid; */
  /* border-color: gray; */
}

#departures-table tr {
  border-bottom: 1px solid #ddd;
  background-color: white;
}

.departures-table__container-theme-dark #departures-table tr {
  border-bottom: 1px solid #45484c;
  background-color: #232527;
}

/* #departures-table tr:first-child {
  border-top: 1px solid #ddd;
}

.departures-table__container-theme-dark #departures-table tr:first-child {
  border-top: 1px solid #45484c;
} */

#departures-table tr:active {
  background-color: #bbbbbb;
}

.departures-table__container-theme-dark #departures-table tr:active {
  background-color: #1c1c1f;
}

/* .live {
  color: blue;
} */

#departures-table .bus-early {
  color: #ffa500;
  /* margin-left: 18.5px; */
}

#departures-table .bus-late {
  color: #ff0000;
  /* margin-left: 18.5px; */
}

#departures-table .bus-on_time {
  color: #008000;
  /* margin-left: 18.5px; */
}

#departures-table .bus-scheduled {
  color: black;
  /* margin-left: 18.5px; */
}

.departures-table__container-theme-dark #departures-table .bus-scheduled {
  color: white;
}

.bus-short-name__REALTIME p {
  display: inline-block;
  background-color: #0000ff;
  color: white;
  border-radius: 6px;
  width: 100%;
  text-align: center;
  font-weight: 600;

  padding: 2px 7px;
  margin: 25px 0 25px 15px;
  /* min-width: 25px; */
}

.bus-short-name__SCHEDULED p {
  display: inline-block;
  /* background-color: blue; */
  /* color: white; */
  border-radius: 6px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  /* border: 1px solid black; */
  color: black;
  background-color: rgb(210, 210, 210);

  padding: 2px 5px;
  margin: 25px 0 25px 15px;
  /* min-width: 30px; */
}

.departures-table__container-theme-dark .bus-short-name__SCHEDULED p {
  color: white;
  background-color: rgb(73, 73, 73);
}

.bus__direction p {
  margin: 0;
  color: black;
  /* width: calc(100vw - 150px); */
  max-width: calc(100% - 40px);
  /* width: 50px; */
  /* margin-left: auto;
  margin-right: auto; */
}

.departures-table__container-theme-dark .bus__direction p {
  color: white;
}

.bus__direction {
  margin-left: 25px;
}

.bus__arriving-time {
  text-align: center;
}

.bus__arriving-time p {
  display: inline-block;
  /* width: 5px; */
  margin: 0;
  padding: 0;
  position: relative;
  right: 15px;
  /* text-align: center; */
  /* position: center; */
}

#bus-icon {
  /* display: inline-block; */
  margin: 0;
  margin-right: 5px;
  height: 13px;
  width: 13px;
  transform: translateY(1px);
  /* top: 80; */
}

#headsign {
  display: inline-flex;
}

.free-space {
  height: 50px;
}

/*  */

.departures-list-control-button {
  background-color: #e9e9e9;
  padding: 10px;
  border: none;
  border-radius: 20px;
  color: black;
  /* color: #535353; */
  font-size: 16px;

  width: calc(50% - 30px);
  height: 40px;
  margin: 20px 15px 0px 15px;
  box-shadow: 0px 0px 10px 0px rgba(100, 100, 111, 0.1);
}

/* rgb(73, 73, 73) */

.departures-table__container-theme-dark .departures-list-control-button {
  background-color: #2f3033;
  color: white;
  box-shadow: 0px 5px 10px 0px rgba(10, 10, 11, 0.2);
}

.departures-list-control-button img {
  width: 22px;
  height: 22px;
  margin-right: 5px;
  margin-top: -5px;
  transform: translateY(40%) rotate(90deg);
}

.show-down img {
  transform: translateY(5%) rotate(270deg);
}

.departures-list-control-button:active {
  background-color: #bbbbbb;
}

.departures-table__container-theme-dark .departures-list-control-button:active {
  background-color: #1c1c1f;
}

/*  */

.departures-list-go-to-top-button-floating {
  position: fixed;
  left: calc(50% - 22px);
  bottom: 30px;
  z-index: 1;
  box-shadow: 0px 0px 29px 0px rgba(100, 100, 111, 0.1);
  background-color: #e9e9e9;
  opacity: 0.8;
  border: none;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  display: none;
}

.departures-table__container-theme-dark .departures-list-go-to-top-button-floating {
  background-color: #2f3033;
  box-shadow: 0px 0px 29px 0px rgba(30, 30, 33, 0.2s);
}

.departures-list-go-to-top-button-floating img {
  transform: rotate(90deg);
  width: 20px;
  height: 20px;
  margin-top: 0px;
}

.departures-list-go-to-top-button-floating:active {
  background-color: #bbbbbb;
}

.departures-table__container-theme-dark .departures-list-go-to-top-button-floating:active {
  background-color: #1c1c1f;
}

#departures-table__information p {
  font-size: 12px;
  color: #93949a;
  /* margin: 0 0 70px 35px; */
  margin: 30px 5vw 70px 5vw;
  text-align: center;
}

#departures-table__information a {
  text-decoration: none;
  color: #0a84fe;
}

/* Filters */

.filters {
  position: sticky;
  top: 0;
  z-index: 1;
  overflow: auto;
  white-space: nowrap;
  padding: 0 15px 15px 15px;
  margin: 0;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.filters::-webkit-scrollbar {
  display: none;
}

.departures-table__container-theme-dark .filters {
  background-color: #232527;
  border-bottom: 1px solid #45484c;
}

.filters p {
  display: inline-block;
  text-decoration: none;
  font-size: 13px;
  color: black;
  background-color: rgb(210, 210, 210);
  padding: 1px 7px;
  margin: 0 5px 0 0;
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  min-width: 30px;
}

.departures-table__container-theme-dark .filters p {
  color: white;
  background-color: rgb(73, 73, 73);
}

.filters .selected, .departures-table__container-theme-dark .filters .selected {
  background-color: #0a84fe;
}

/* /Filters */

/* @media screen and (max-width: 600) {
  #departures-table {
    width: 300px;
    width: 300px;
    max-width: 600px;
  }
} */