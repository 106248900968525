/* .swipable-edge-drawer {
  position: center;
} */

h2 {
  /* width: calc(100vw - 95px); */
  height: 100%;
  margin-left: 15px;
  margin-right: 100px;
  display: inline-block;
  padding-bottom: 20px;
  color: black;
  /* transition: margin 0.5s; */
}

.upper-part__container-theme-dark h2 {
  color: white;
}

.upper-part__container #stop-info, .upper-part__container-theme-dark #stop-info {
  position: absolute;
  bottom: 0;
  display: flex;
  padding: 0 0 0 15px;
  /* height:14px; */
}

/* .upper-part__container #stop-info:active {
  background-color: #bbbbbb;
}

.upper-part__container-theme-dark #stop-info:active {
  background-color: #1c1c1f;
} */

.upper-part__container #stop-info img, .upper-part__container-theme-dark #stop-info img {
  height: 14px;
  width: 14px;
  /* margin: 16px 0 0 15px; */
  margin: 16px 5px 0 0;
  padding: 2px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(100, 100, 111, 0.2);
}

.upper-part__container #stop-info p, .upper-part__container-theme-dark #stop-info p {
  transform: translateY(-1px);
  /* padding: 0 0 0 5px; */
  color: gray;
  font-size: 14px;
}

/* .upper-part__container-theme-dark p {
  position: absolute;
  bottom: 0;
  transform: translateX(15px) translateY(-1px);
  color: gray;
  font-size: 14px;
} */

.buttons__container {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 20px;
  -ms-transform: translateY(-90%);
  transform: translateY(-90%);
  margin: 0;
  padding: 0;
  width: 70px;
  height: 30px;
}

#show-options-button  {
  background-color: #e9e9e9;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 0;
  margin: 0;
  padding: 0;
}

.upper-part__container-theme-dark #show-options-button {
  background-color: #37383d;
}

#close-button  {
  background-color: #e9e9e9;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 0;
  padding: 0;
  margin-left: 10px;
  margin-top: 3px;
  transform: translateY(-1px);
}

.upper-part__container-theme-dark #close-button {
  background-color: #37383d;
}

#close-button:active, #show-options-button:active {
  background-color: #bbbbbb;
  /* box-shadow: 0px 0px 1px 1px #aaaaaa; */
}

.upper-part__container-theme-dark #close-button:active, .upper-part__container-theme-dark #show-options-button:active {
  background-color: #1c1c1f;
}

.safe-area {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 30px;
  width: 100%;
  z-index: 10000;
  opacity: 0;
}

.upper-part__container {
  /* padding-bottom: 20px; */
}

/* @keyframes example {
  from {margin-top: 30px;}
  to {margin-top: -15px;}
} */

/* #bus-stop__select__dropdown {
  z-index: 2000;
  margin-top: -30px;
  padding-left: 15px;
  padding-right: 15px;
} */

.route-name {
  display: flex;
}

.route-name p {
  /* display: inline-block; */
  /* display: flex; */
  padding: 2px 13px 2px 13px;
  margin: 30px 0 38px 15px;
  background-color: rgb(210, 210, 210);
  color: black;
  border-radius: 6px;
  font-weight: 600;
  width: fit-content;
  height: fit-content;
}

.route-name .REALTIME {
  background-color: blue;
  color: white;
}

.route-name #headsign {
  background-color: inherit;
  color: black;
  margin: 31px 25px 13px 15px;
  padding: 0 40px 2px 0;
  font-weight: 600;
  font-size: 17px;
}

.upper-part__container-theme-dark .route-name #headsign {
  color: white;
}

#bus-info .buttons__container {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 30px;
  -ms-transform: translateY(-70%);
  transform: translateY(-70%);
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;
}

/* .upper-part__container #bus-info .provider img, .upper-part__container-theme-dark #bus-info .provider img {
  height: 14px;
  width: 14px;
  margin: 16px 5px 0 0;
  padding: 2px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(100, 100, 111, 0.2);
} */