.install-instructions__container {
  z-index: 10000;
  display: none;
  /* opacity: 0; */
  /* visibility: hidden; */
  /* transition: visibility 1s ease; */
}

.install-instructions__container-theme-dark {
  z-index: 10000;
  display: none;
  /* opacity: 0; */
  /* visibility: hidden; */
  /* transition: visibility 1s ease; */
}

.install-instructions__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
  z-index: 10000;
}

.install-instruction-card {
  position: fixed;
  top: 15vh;
  left: 10vw;
  /* height: 60vh; */
  width: 80vw;
  /* background-color: aqua; */
  background-color: #f2f2f2;
  z-index: 10001;
  border-radius: 20px;
  padding: 20px 20px 50px 20px;
}

.install-instructions__container-theme-dark .install-instruction-card {
  background-color: #1c1c1e;
}