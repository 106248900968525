.report-a-problem__container {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
}

.App-content-theme-dark .report-a-problem__container {
  background-color: black;
}

.report-a-problem__nav {
  height: 70px;
  width: 100vw;
  position: relative;
  top: 10px;
  left: 0;
  display: inline-flex;
  border-bottom: 1px solid #ddd;
  margin: 0 0 10px 0;
}

.App-content-theme-dark .report-a-problem__container .report-a-problem__nav {
  border-bottom: 1px solid #45484c;
}

.report-a-problem-return-button {
  /* position: absolute; */
  /* left: 0; */
  /* top: 0; */
  height: 40px;
  width: 40px;
  border: none;
  border-radius: 50%;
  /* background-color: #e9e9e9; */
  background-color: inherit;
  transform: translateY(10px);
  /* vertical-align: middle; */
}

.report-a-problem-return-button:active {
  background-color: #bbbbbb;
}

.App-content-theme-dark .report-a-problem__container .report-a-problem-return-button:active {
  background-color: #1c1c1f;
}

.report-a-problem-return-button img {
  height: 25px;
  width: 25px;
  transform: translateY(1px);
}

.report-a-problem__container h1 {
  margin: 0;
  padding: 10px 0 20px 0;
  color: black;
  /* text-align: center; */
}

.App-content-theme-dark .report-a-problem__container h1 {
  margin: 0;
  padding: 10px 0 20px 0;
  color: white;
}

.report-a-problem__attach-data {
  width: 100vw;
}

.report-a-problem__attach-data h3 {
  margin: 30px 0 0 0;
  color: black;
  text-align: center;
}

.App-content-theme-dark .report-a-problem__container .report-a-problem__attach-data h3 {
  color: white;
}

#report-a-problem-input-text {
  color: black;
  width: 90vw;
  height: 50px;
  /* margin: 10px; */
  display: block;
  margin: 15px auto 0 auto;
  /* margin: 15px 10px 0 10px; */
  border-radius: 15px;
  border: none;
  background-color: inherit;
  -webkit-appearance: none;
  box-shadow: 0px 0px 10px 0px rgba(100, 100, 111, 0.2);

  padding: 0 10px 0 10px;
  font-size: 15px;
}

.App-content-theme-dark .report-a-problem__container #report-a-problem-input-text {
  color: white;
  box-shadow: 0px 0px 10px 0px rgba(139, 139, 152, 0.3);
}

#report-a-problem-input-text:focus {
  outline: none;
  border: 1px solid #e9eaec;
  box-shadow: none;
}

.App-content-theme-dark .report-a-problem__container #report-a-problem-input-text:focus {
  border: 1px solid #45484c;
}

.report-a-problem-input-photo__container button {
  background-color: #e9e9e9;
  border: none;
  -webkit-appearance: none;
  box-shadow: 0px 0px 20px 0px rgba(100, 100, 111, 0.1);
  border-radius: 20px;
  display: block;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 15px;
  font-size: 13px;
  color: black;
}

.App-content-theme-dark .report-a-problem__container .report-a-problem-input-photo__container button {
  background-color: #2f3033;
  color: white;
}

.report-a-problem-input-photo__container button:active {
  outline: none;
  border: 1px solid #e9eaec;
  box-shadow: none;
}

.App-content-theme-dark .report-a-problem__container .report-a-problem-input-photo__container button:active {
  border: 1px solid #45484c;
}

#report-a-problem-input-photo {
  display: none;
}

.report-a-problem-input-photo__container #report-a-problem-chosen-photo {
  background-color: #e9e9e9;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px rgba(100, 100, 111, 0.2);
  display: none;
  margin: 15px auto -10px auto;
  padding: 10px;
  max-height: 30vh;
  max-width: 60vw;
}

.App-content-theme-dark .report-a-problem__container .report-a-problem-input-photo__container #report-a-problem-chosen-photo {
  background-color: #45484c;
}

/*  */

#report-a-problem-send-button {
  border: none;
  background-color: #e9e9e9;
  -webkit-appearance: none;
  box-shadow: 0px 0px 20px 0px rgba(100, 100, 111, 0.2);
  border-radius: 20px;
  padding: 0 70px;
  height: 40px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -0px);
  bottom: 20vh;
}

.App-content-theme-dark .report-a-problem__container #report-a-problem-send-button {
  background-color: #2f3033;
}

#report-a-problem-send-button:active {
  /* background-color: #bbbbbb; */
  outline: none;
  border: 2px solid #e9eaec;
  box-shadow: none;
}

.App-content-theme-dark .report-a-problem__container #report-a-problem-send-button:active {
  border: 1px solid #45484c;
}

#report-a-problem-send-button h3 {
  font-size: 15px;
  color: black;
  padding: 0;
  margin: 0;
}

.App-content-theme-dark .report-a-problem__container #report-a-problem-send-button h3 {
  color: white;
}

#report-a-problem-send-button .lds-spinner {
  transform: translateY(-19px) translateX(-18px);
}